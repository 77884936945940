import cn from 'classnames'
import styles from '../styles/home.module.scss';
import HomeImg from '../assets/home_img.png'
import Mission from '../assets/mission.jpg'
import About from '../assets/about.jpg'
import Circle from '../assets/circle.png'
import Community from '../assets/community.png'
import NFTs from '../assets/nfts.png'
import Stats from '../assets/stats.png'
// import CrossScrollable from "../components/CrossScrollable";
// import Testimonial from "../components/Testimonial";


// const sliderData = {
//     upper: ['Marketers', '2D Artists', 'NFT Creators', 'Smart-Contract Integrations', 'Animators'],
//     bottom: ['Community Manager',  'Front-End Developers', 'Back-End Developers', 'Solidity developers', 'UX/UI Designers', '3D Artists']
// };

const staticData = [
    {
        id: 'static_1',
        date: '10.04.2021',
        description: 'An interactive theatre experience that will provide locals and tourists alike an unprecedented… glimpse into the city’s most haunted sights.',
        name: 'Bill Games',
        position: 'Developer @Apple',
        image: '/assets/testmon.png'
    },
    {
        id: 'static_2',
        date: '10.04.2021',
        description: 'An interactive theatre experience that will provide locals and tourists alike an unprecedented… glimpse into the city’s most haunted sights.',
        name: 'Bill Games',
        position: 'Developer @Apple',
        image: '/assets/testmon.png'
    },
    {
        id: 'static_3',
        date: '10.04.2021',
        description: 'An interactive theatre experience that will provide locals and tourists alike an unprecedented… glimpse into the city’s most haunted sights.',
        name: 'Bill Games',
        position: 'Developer @Apple',
        image: '/assets/testmon.png'
    }
]

const Home = () => {
    return (
        <section id='home' className={styles.section}>
            <div className={`container ${styles.container}`}>
                <div className={styles.content}>
                    <h1>
                        Welcome to <br/>
                        OD<span className='color--primary'>D </span>Republic
                    </h1>
                    <p>
                        By NFT creators, for NFT creators. We are the first freelancing platform with a concentration on NFT and blockchain services — anything from the metaverse to GameFi. Unlike any other freelancing platform like this (if you’ll find one), we take full responsibility for quality control and project management for all of our clients.
                    </p>
                    {/*<Link href='/signup'>*/}
                    {/*    <a className='btn--dark'>*/}
                    {/*        <span>Get Started</span>*/}
                    {/*    </a>*/}
                    {/*</Link>*/}
                    <p className='mt-5'>
                        When you become a member of our republic as a freelancer, you’ll gain access to your very own profile where you can track your ongoing and past projects, view milestones, key insights, and more.
                    </p>
                </div>
                <div className={styles.image_wrapper}>
                    <div className={styles.image}>
                        <img
                            src={HomeImg}
                            width={500}
                            height={700}
                        />
                    </div>
                </div>
            </div>
            <div className={`spacing-xl ${styles.stack}`}>

               <div className={cn('container', styles.about)}>
                   <div>
                       <h2 className='linedTitle'>
                           About ODD Republic
                           <span className='lines'/>
                       </h2>
                       <p>
                           Odd Republic is a one-of-a-kind professional freelancing platform connecting talents with clients. We’re up-and-coming but already stand our ground in the industry as the first platform with a heavy emphasis on Web3, NFTs, GameFi, and much more. Leave all the laborious and time-consuming elements of freelancing to us — we’ll assign a dedicated project manager to handle client communications and provide risk insurance.
                       </p>
                   </div>
                   <div className={styles.about_banner}>
                       <img
                           src={About}
                           alt='Features Circle'
                       />
                   </div>
               </div>
                <div className={cn('container', styles.mission)}>
                    <div className={styles.mission_banner}>
                        <img
                            src={Mission}
                            layout='fill'
                            alt='Features Circle'
                        />
                    </div>
                    <div>
                        <h2 className='linedTitle'>
                            Our Mission
                            <span className='lines'/>
                        </h2>
                        <p>
                            Odd Republic was born when a team of freelancers and NFT enthusiasts came together to bring solutions to the problems they faced throughout their careers. We know firsthand what’s it like to not find the perfect platform or digital tools to support the smooth progression of a project from start to finish. That’s why we’re taking management and risks associated with projects into our own hands, not only to lift some weight off of your shoulders but to take freelancing to new heights in professionalism. Our mission is to make the fast-paced world of blockchain technologies accessible to freelancers along with product owners who want to bring their wildest NFT collection ideas to life.
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.video_bar}>
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/QgqWJ_jY7P8"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>

            <div className={`spacing-xl ${styles.features}`}>
                <div className={styles.feature_image}>
                    <img
                        src={Circle}
                        width={578}
                        height={578}
                        alt='Features Circle'
                    />
                </div>
                <div className={`container ${styles.features_content}`}>
                    <h2>How it Works</h2>
                    <div className={styles.features_container}>
                        <div className={styles.feature_item}>
                            <h3>1. Register</h3>
                            <p>Just your standard registration (you know the drill)</p>
                        </div>
                        <div className={styles.feature_item}>
                            <h3>2. Open an Inquiry</h3>
                            <p>Note that you are open to a new project and mention the type. Our account manager will find a suitable project and close the deal with the client.</p>
                        </div>
                        <div className={styles.feature_item}>
                            <h3>3. Start Working</h3>
                            <p>Our project manager will pass on all of the details and planning to you to get started. Receive payment per milestone.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.testimonial_bg}>
                <div className={`container ${styles.testimonial_wrapper}`}>

                </div>
            </div>
            <div className={`spacing-xl ${styles.testimonial_container}`}>
               <div className="container">
                   <h2 className='linedTitle'>
                       Let’s crunch the numbers...
                       <span className={`lines lines_left`}/>
                   </h2>
                   <div className={styles.dots}>
                       <h4 className='color--primary'>
                           100+ happy customers
                       </h4>
                   </div>
               </div>
                <div className='container'>
                    <div className={styles.statistics}>
                        <div className={styles.statistic_item}>
                            <div className={styles.statistic_image}>
                                <img
                                    src={Community}
                                    width={140}
                                    height={88}
                                />
                            </div>
                            <h6>
                                <b>200+</b>
                                Freelancer
                            </h6>
                        </div>
                        <div className={styles.statistic_item}>
                            <div className={styles.statistic_image}>
                                <img
                                    src={NFTs}
                                    width={90}
                                    height={100}
                                />
                            </div>
                            <h6>
                                <b>500K+</b><br/>
                                NFTs Generated
                            </h6>
                        </div>
                        <div className={styles.statistic_item}>
                            <div className={styles.statistic_image}>
                                <img
                                    src={Stats}
                                    width={88}
                                    height={88}
                                />
                            </div>
                            <h6>
                                <b>$60M+</b><br/>
                                Client traded volume
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default Home;