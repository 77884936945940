import { Link } from "react-router-dom";
import styles from './footer.module.scss';
import {socialData} from "../../utils";
import SocialLink from "../SocialLink";



const Footer = () => (
    <footer className={`spacing ${styles.container}`}>
        <div className={`container ${styles.wrapper}`}>
           <div>
               <h2>
                   Contact <br/>
                   Information
               </h2>
               <a
                   href="mailto:partners@oddrepublic.io"
                   target='_blank'
                   rel='noreferrer'
                   className={styles.social_link}
               >
                   partners@oddrepublic.io
               </a>
           </div>
            <div className="klaviyo-form-RJSWf3"></div>
            <div className={styles.links}>
                <Link to='/terms-and-conditions' className={styles.navigations}>
                        Terms & Conditions
                </Link>
                <Link to='/privacy-policy' className={styles.navigations}>
                        Privacy & Policy
                </Link>
                <div className={styles.socials_container}>
                <span>
                    Follow <br/>
                    Us
                </span>
                    <div className={styles.socials_wrapper}>
                        {socialData.map(({id, icon, link}) => (
                            <SocialLink
                                key={id}
                                iconName={icon}
                                socialLink={link}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.copyright}>
            <h6>Ⓒ All Rights Reserved 2022: ODD REPUBLIC AM LLC</h6>
        </div>
    </footer>
)

export default Footer;