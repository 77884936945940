import styles from '../styles/about.module.scss';
import LinkButton from "../components/LinkButton";
import PortfolioItem from "../components/PortfolioItem";
import fakeCollectionData from "../utils/fakeCollectionData";
import Portfolio1 from '../assets/slider/img_1.jpg'
import Portfolio3 from '../assets/slider/img_3.jpg'
import Portfolio5 from '../assets/slider/img_5.jpg'
import Portfolio12 from '../assets/slider/img_12.jpg'



const About = () => {
    return(
        <>
            <section className='spacing-xl'>
                <div className={`container ${styles.container}`}>
                    <h1 className={`${styles.title} linedTitle`}>
                        About <br/>
                        Our Community
                        <span className='lines'/>
                    </h1>
                    <div className={styles.content}>
                        <span className='line-sm'/>
                        <h3 className='fw-normal'>
                            Our network of specialists is a daily growing community of over 100 professionals with a wide skill set including but not limited to php, javascript, python, unity, web developers, illustrators, 3D artists and animators, game designers and developers, graphic designers, etc.
                        </h3>
                    </div>
                </div>
                <div className={`container ${styles.experience}`}>
                    <div className={styles.experience_title}>
                        <h2>
                            Experience <br/>
                            Next Generation <br/>
                            Freelancing Services
                            <span className={styles.experience_icon}></span>
                        </h2>
                        {/*<LinkButton to='/signup'>*/}
                        {/*    <span>Start Your Project</span>*/}
                        {/*</LinkButton>*/}
                    </div>
                    <div className={styles.static_content}>
                        <div className={styles.static_item}>
                            <h4>Tired of countless interviews?</h4>
                            <h6 className='fw-normal'>Platform takes care of the whole screening and hiring process, and finds best specialists for every project.</h6>
                        </div>
                        <div className={styles.static_item}>
                            <h4>Just order and sit back</h4>
                            <h6 className='fw-normal'>You get a dedicated project manager who does all planning, communication and management for you.</h6>
                        </div>
                        <div className={styles.static_item}>
                            <h4>We’ve got your back covered</h4>
                            <h6 className='fw-normal'>Forget outdated deadlines, low quality results and unprofessional freelancers, we deliver what we promise.</h6>
                        </div>
                    </div>
                </div>
                <div className="container spacing-xl">
                    <h2 className={`linedTitle text-center ${styles.freelancers_title}`}>
                        What’s a better freelancing experience?
                        <span className='lines'/>
                    </h2>
                    <div className={styles.freelance_container}>
                        <div className={styles.freelance_item}>
                            <h3>
                                Easy Communication
                            </h3>
                            <p>
                                Talk to one <span className='color--primary'>professional project manager</span> instead of a dozen freelancers.
                            </p>
                        </div>
                        <div className={styles.freelance_item}>
                            <h3>
                                Clear Expectations
                            </h3>
                            <p>
                                You receive <span className='color--primary'>detailed planning</span> before the start of a project.
                            </p>
                        </div>
                        <div className={styles.freelance_item}>
                            <h3>
                                Quality Control
                            </h3>
                            <p>
                                Don’t worry about redoing things anymore, we deliver only final <span className='color--primary'>high quality results</span>.
                            </p>
                        </div>

                    </div>
                </div>
            </section>
            <section className={styles.portfolio}>
                <div className={`container spacing-xl ${styles.portfolio_container}`}>
                    <div className={styles.portfolio_content}>
                        <h2 className={`linedTitle ${styles.portfolio_title}`}>
                            What’s a better freelancing experience?
                            <span className='lines'/>
                        </h2>
                        <p className='color--primary'>
                            We’ve helped 50K NFTs to be created
                        </p>
                        <LinkButton to='/collections'>
                            All Collections
                        </LinkButton>
                    </div>
                    <div className={styles.portfolio_items_container}>
                        <PortfolioItem
                            imageSrc={Portfolio1}
                            imageWidth={190}
                            imageHeight={254}
                            socialStatus={fakeCollectionData[2]?.socials}
                        />
                        <div className={styles.vertical_portfolio}>
                            <PortfolioItem
                                imageSrc={Portfolio3}
                                imageWidth={190}
                                imageHeight={190}
                                socialStatus={fakeCollectionData[2]?.socials}
                            />
                            <PortfolioItem
                                imageSrc={Portfolio5}
                                imageWidth={190}
                                imageHeight={190}
                                socialStatus={fakeCollectionData[2]?.socials}
                            />
                        </div>
                        <PortfolioItem
                            imageSrc={Portfolio12}
                            imageWidth={190}
                            imageHeight={254}
                            socialStatus={fakeCollectionData[2]?.socials}
                        />
                    </div>
                </div>
            </section>
        </>
    )
}

export default About