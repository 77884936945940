import { Link } from "react-router-dom";
import {useRef, useState} from "react";
import cn from 'classnames'
import styles from './header.module.scss';
import Brand from '../../assets/brand.svg'


const Header = () => {
    const burgerRef = useRef(null);
    const [isOpened, setIsOpened] = useState(false);

    const handleBurgerClick = (e) => {
        if (isOpened) {
            setIsOpened(false)
            document.documentElement.removeAttribute("style");
            return;
        }
        setIsOpened(true)
        document.documentElement.setAttribute("style", "overflow: hidden");
    };

    const handleLinkClick = () => {
        setIsOpened(false)
        document.documentElement.removeAttribute("style");
    }

    return (
       <>
           <header className={styles.header_container}>
               <div className={styles.header_brand}>
                   <Link to='/'>
                           <img
                               src={Brand}
                               alt='Odd Republic'
                           />
                   </Link>
                   <h6 className={styles.header_brand_txt}>BETA</h6>
               </div>

               <nav>
                   <div
                       ref={burgerRef}
                       className={`${styles.burger_bar_container} ${
                           cn({
                               [styles.active]: isOpened
                           })
                       }`}
                       onClick={handleBurgerClick}
                   >
                       <div className={`${styles.bars} ${styles.bar1}`}></div>
                       <div className={`${styles.bars} ${styles.bar2}`}></div>
                       <div className={`${styles.bars} ${styles.bar3}`}></div>
                   </div>
                   <div
                       className={styles.header_content}
                   >
                       <ul>
                           <li className={styles['nav-item']}>
                               <Link to='/about' onClick={handleLinkClick} className={styles['nav-link']}>
                                       About
                               </Link>
                           </li>
                           <li className={styles['nav-item']}>
                               <Link to='/our-work' onClick={handleLinkClick} className={styles['nav-link']}>
                                       Our Work
                               </Link>
                           </li>
                           <li className={styles['nav-item']}>
                               <Link to='/freelancers' onClick={handleLinkClick} className={styles['nav-link']}>
                                       Freelancers
                               </Link>
                           </li>
                       </ul>
                   </div>
                   {/*<div*/}
                   {/*    className={styles.content_right}*/}
                   {/*>*/}
                   {/*    <ul>*/}
                   {/*        <li className={`${styles['nav-item']}`}>*/}
                   {/*            <Link to='/signup?role=freelancer'>*/}
                   {/*                <a className={styles['nav-link']}>*/}
                   {/*                    <span>Apply as a Freelancer</span>*/}
                   {/*                </a>*/}
                   {/*            </Link>*/}
                   {/*        </li>*/}
                   {/*        <li className={`${styles['nav-item']} `}>*/}
                   {/*            <Link to='/signup'>*/}
                   {/*                <a className={`${styles['nav-link']} ${styles['nav-item_button']}`}>*/}
                   {/*                    <span>Start Your Project</span>*/}
                   {/*                </a>*/}
                   {/*            </Link>*/}
                   {/*        </li>*/}
                   {/*        <li className={`${styles['nav-item']}`}>*/}
                   {/*            <Link to='/login'>*/}
                   {/*                <a className={styles['nav-link']}>*/}
                   {/*                    <span>Log in</span>*/}
                   {/*                </a>*/}
                   {/*            </Link>*/}
                   {/*        </li>*/}
                   {/*    </ul>*/}
                   {/*</div>*/}
                   <span className={styles.four_point_star} />
               </nav>
           </header>
       </>
    )
};

export default Header;