import styles from "../styles/freelancers.module.scss";

const preferences = [
    {
        id: '01',
        title: 'Suitable',
        content: 'No more bidding, platform finds the best projects suiting your skills.'
    },
    {
        id: '02',
        title: 'Remote',
        content: 'Work from whenever you want, freelancing at its best.'
    },
    {
        id: '03',
        title: 'Milestones',
        content: 'Get paid by milestones and projects, no more time tracking.'
    },
    {
        id: '04',
        title: 'Project Manager',
        content: 'Professional project managers handle communication with clients.'
    },
    {
        id: '05',
        title: 'Payment',
        content: '100% refunding for all projects, you get paid for the job done in any case.'
    },
]


const Freelancers = () => {
    return (
        <>
            <section className={styles.section}>
                <div className={`container ${styles.container}`}>
                    <h1 className='linedTitle'>
                        For <br/>
                        <b className='color--primary'>Freelancers</b>
                        <span className='lines'/>
                    </h1>

                    <div className={styles.content}>
                        <span className='line-sm'/>
                        <h3 className='fw-normal m-0'>
                            Join the growing freelancing community of professionals.
                        </h3>
                    </div>
                </div>
                <div className={`container ${styles.content_white}`}>
                    <h3 className='fw-normal m-0'>
                        Odd Republic is a professional freelancing community of illustrators, 2D and 3D artists, NFT
                        artists, web developers, software engineers and other awesome specialists ready to jump on our
                        epic
                        freelancing projects from our epic clients.
                    </h3>
                </div>
            </section>
            <section className={styles.white_section}>
                <div className='container spacing'>
                    <h2>Why Join Us ?</h2>
                    <div className={styles.preference_container}>
                        {preferences.map(({id, title, content}) => (
                            <div key={id} className={styles.preference_item}>
                                <h4 className={styles.preference_id}>{id}</h4>
                                <h4 className={styles.preference_title}>{title}</h4>
                                <h6 className='fw-normal'>{content}</h6>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Freelancers