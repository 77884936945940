import styles from "./socialLink.module.scss";
import cn from "classnames";

const SocialLink = ({ iconName, socialLink, isWhite }) => (
  <a
    href={socialLink}
    target="_blank"
    rel="norefferer"
    className={`${styles.btn} ${cn({
      [styles.btn_white]: isWhite,
    })}`}
  >
    <i className={iconName}></i>
  </a>
);

export default SocialLink;
