import styles from '../styles/collections.module.scss';
import fakeCollectionData from "../utils/fakeCollectionData";
import PortfolioItem from "../components/PortfolioItem";
import Portfolio from '../assets/portfolio.png'

const OurWork = () => {
    return(
        <>
            <section className={styles.banner}>
               <div>
                   <h1>NFT Created by <span>John Doe artist</span> <br/> </h1>
                   <h2 className='m-0'>
                       by <span className="color--primary">ODD Republic</span> first NFT specialised freelancing platform.
                   </h2>
               </div>
                <div className={styles.banner_image}>
                    <img
                        src={Portfolio}
                        alt='Features Circle'
                    />
                </div>
            </section>
            <section className={styles.container}>
                <div className='container spacing-xl'>
                    <h2 className='text-center'>
                        Some of our NFT Collections
                    </h2>
                    <div className={styles.portfolio_container}>
                        {fakeCollectionData.map(({imageSrc,imageWidth, imageHeight, socials, id, description, title}) => (
                            <PortfolioItem
                                key={id}
                                imageSrc={imageSrc}
                                imageWidth={imageWidth}
                                imageHeight={imageHeight}
                                socialStatus={socials}
                                description={description}
                                title={title}
                            />
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurWork;