import { useState, useRef, useEffect } from "react";
import cn from 'classnames'
import styles from './dropdown.module.scss';

const Dropdown = ({content, selectedItem, setSelectedItem, wrapperClassName = ''}) => {
    const [toggle, setToggle] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => setToggle(() => !toggle);

    const handleDropdownClick = e => {
        setSelectedItem(() => e.currentTarget.innerText)
        setToggle(!toggle);
    };

    const handleOutsideClick = (e) => {
        if (
            (dropdownRef.current &&
                dropdownRef.current?.contains(e.target))
        ) {
            return;
        }

        setToggle(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    });

    return(
        <div ref={dropdownRef} className={cn(styles.wrapper, wrapperClassName)}>
            <div>
                <label htmlFor="dropdownSelected" className={styles.selected_label}>I'm a</label>
                <button id='dropdownSelected' type='button' className={styles.selected} onClick={toggleDropdown}>
                    {selectedItem || content[0]}
                </button>
            </div>
            <div className={cn(styles.content_container, toggle && styles.active)}>
                {content.map((item, index) => (
                    <button key={index} type='button' className={styles.item} onClick={handleDropdownClick}>
                        {item}
                    </button>
                ))}
            </div>
        </div>
    )
}

export default Dropdown