import Img6 from "../assets/slider/img_6.JPG";
import Img18 from "../assets/slider/img_18.jpeg";
import Img52 from "../assets/slider/img_52.jpeg";
import Img23 from "../assets/slider/img_23.jpg";
import Img26 from "../assets/slider/img_26.jpg";
import Img22 from "../assets/slider/img_22.jpg";
import Img53 from "../assets/slider/img_53.jpg";
import Img2 from "../assets/slider/img_2.png";
import Img24 from "../assets/slider/img_24.png";
import Img30 from "../assets/slider/img_30.png";
import Img20 from "../assets/slider/img_20.png";
import Img25 from "../assets/slider/img_25.png";
import Img15 from "../assets/slider/img_15.png";
import Img16 from "../assets/slider/img_16.JPG";
import Img1 from "../assets/slider/img_1.jpg";
import Img21 from "../assets/slider/img_21.png";
import Img27 from "../assets/slider/img_27.jpg";
import Img28 from "../assets/slider/img_28.png";
import Img12 from "../assets/slider/img_12.jpg";
import Img29 from "../assets/slider/img_29.png";
import Img34 from "../assets/slider/img_34.jpg";
import Img3 from "../assets/slider/img_3.png";
import Img14 from "../assets/slider/img_14.png";
import Img35 from "../assets/slider/img_35.png";
import Img36 from "../assets/slider/img_36.jpg";
import Img17 from "../assets/slider/img_17.png";
import Img37 from "../assets/slider/img_37.jpg";
import Img38 from "../assets/slider/img_38.png";
import Img9 from "../assets/slider/img_9.png";
import Img19 from "../assets/slider/img_19.png";
import Img41 from "../assets/slider/img_41.png";
import Img42 from "../assets/slider/img_42.png";
import Img43 from "../assets/slider/img_43.jpg";
import Img44 from "../assets/slider/img_44.jpg";
import Img46 from "../assets/slider/img_46.jpg";
import Img47 from "../assets/slider/img_47.jpg";
import Img4 from "../assets/slider/img_4.png";
import Img8 from "../assets/slider/img_8.png";
import Img48 from "../assets/slider/img_48.png";
import Img49 from "../assets/slider/img_49.png";
import Img50 from "../assets/slider/img_50.png";
import Img51 from "../assets/slider/img_51.jpg";

const fakeCollectionData = [
  {
    id: "6",
    imageSrc: Img6,
    title: "Gambling Apes",
    date: "",
    imageWidth: 300,
    imageHeight: 300,
    description: "Sold out",
    socials: [
      {
        src: "https://gamblingapes.com",
        icon: "fas fa-external-link-alt",
      },
      {
        src: "https://looksrare.org/collections/0x90cA8a3eb2574F937F514749ce619fDCCa187d45",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "18",
    imageSrc: Img18,
    title: "Angry Apes United",
    date: "",
    imageWidth: 300,
    imageHeight: 300,
    description: "Sold out",
    socials: [
      {
        src: "https://www.angryapesunited.com/",
        icon: "fas fa-external-link-alt",
      },
      {
        src: "https://opensea.io/collection/angryapesunited",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "52",
    imageSrc: Img52,
    title: "FASHION APE NFT",
    date: "",
    imageWidth: 300,
    imageHeight: 300,
    description: "Sold out",
    socials: [
      {
        src: "https://opensea.io/collection/fashionapenft",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "32",
    imageSrc: Img23,
    imageWidth: 300,
    imageHeight: 300,
    title: "Perilous Petz",
    date: "",
    description: "Launched",
    socials: [
      {
        src: "https://opensea.io/collection/perilous-petz-official",
        icon: "fa-opensea",
      },
      {
        src: "https://perilouspetz.io/",
        icon: "fas fa-external-link-alt",
      },
    ],
  },
  {
    id: "26",
    imageSrc: Img26,
    title: "Mutant Ape Planet",
    imageWidth: 300,
    imageHeight: 300,
    date: "",
    description: "Launched",
    socials: [
      {
        src: "https://www.mutantapeplanet.com/",
        icon: "fas fa-external-link-alt",
      },
      {
        src: "https://opensea.io/collection/mutant-ape-planet",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "31",
    imageSrc: Img22,
    imageWidth: 300,
    imageHeight: 300,
    title: "Jungle Destroyers",
    date: "",
    description: "Sold out",
    socials: [
      {
        src: "https://opensea.io/collection/jungle-destroyers-official",
        icon: "fa-opensea",
      },
      {
        src: "https://www.angryapesunited.com/",
        icon: "fas fa-external-link-alt",
      },
    ],
  },
  {
    id: "53",
    imageSrc: Img53,
    imageWidth: 300,
    imageHeight: 300,
    title: "Meta Sneakers Genesis",
    date: "",
    description: "Sold out",
    socials: [
      {
        src: "https://opensea.io/collection/meta-sneakers-genesis",
        icon: "fa-opensea",
      },
      {
        src: "https://metasneakersnft.io/",
        icon: "fas fa-external-link-alt",
      },
    ],
  },
  {
    id: "2",
    imageSrc: Img2,
    imageWidth: 300,
    imageHeight: 300,
    title: "OG Astro babies Solana",
    date: "",
    description: "Sold out",
    socials: [
      {
        src: "https://www.astrobabies.io/",
        icon: "fas fa-external-link-alt",
      },
    ],
  },
  {
    id: "24",
    imageSrc: Img24,
    imageWidth: 300,
    imageHeight: 300,
    title: "Party Grandpa | Retirement Club",
    date: "",
    description: "Sold out",
    socials: [
      {
        src: "https://opensea.io/collection/retirementclubnft",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "37",
    imageSrc: Img37,
    imageWidth: 300,
    imageHeight: 300,
    title: "Retirement Club Babies",
    date: "",
    description: "Launched",
    socials: [
      {
        src: "https://opensea.io/collection/retirementclubnft",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "30",
    imageSrc: Img30,
    imageWidth: 300,
    imageHeight: 300,
    title: "Astro Babies Apes Solana",
    date: "",
    description: "Sold out",
    socials: [
      {
        src: "https://www.astrobabies.io/",
        icon: "fas fa-external-link-alt",
      },
    ],
  },
  {
    id: "20",
    imageSrc: Img20,
    imageWidth: 300,
    imageHeight: 300,
    title: "Astro Babies Pups Solana",
    date: "",
    description: "upcoming",
    socials: [
      {
        src: "https://www.astrobabies.io/",
        icon: "fas fa-external-link-alt",
      },
    ],
  },
  {
    id: "25",
    imageSrc: Img25,
    imageWidth: 300,
    imageHeight: 300,
    title: "Degenerate Granny",
    date: "",
    description: "Sold out",
    socials: [
      {
        src: "https://opensea.io/collection/retirementclubnft",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "15",
    imageSrc: Img15,
    title: "The Awakening",
    imageWidth: 300,
    imageHeight: 300,
    date: "",
    description: "Sold Out",
    socials: [
      {
        src: "https://www.awakeningnft.io/",
        icon: "fas fa-external-link-alt",
      },
      {
        src: "https://opensea.io/collection/the-awakening-comic",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "16",
    imageSrc: Img16,
    imageWidth: 300,
    imageHeight: 300,
    title: "Invokers",
    date: "",
    description: "Launched",
    socials: [
      {
        src: "https://invokersnft.com/",
        icon: "fas fa-external-link-alt",
      },
      {
        src: "https://magiceden.io/marketplace/invokersnft",
        icon: "fa-magic-eden",
      },
    ],
  },
  {
    id: "1",
    imageSrc: Img1,
    title: "Wall street dropouts",
    date: "",
    description: "Launched",
    imageWidth: 300,
    imageHeight: 300,
    socials: [
      {
        src: "https://wallstreetdropouts.com/",
        icon: "fas fa-external-link-alt",
      },
      {
        src: "https://opensea.io/collection/wallstreet-dropouts",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "21",
    imageSrc: Img21,
    imageWidth: 300,
    imageHeight: 300,
    title: "Corporate Crypto Club",
    date: "",
    description: "Launched",
    socials: [
      {
        src: "https://corporatecryptoclub.com/",
        icon: "fas fa-external-link-alt",
      },
      {
        src: "https://opensea.io/collection/corporate-crypto-club",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "27",
    imageSrc: Img27,
    imageWidth: 300,
    imageHeight: 300,
    title: "Cryptolex NFT",
    date: "",
    description: "Launched",
    socials: [
      {
        src: "https://cryptolex-nft.io/",
        icon: "fas fa-external-link-alt",
      },
      {
        src: "https://opensea.io/collection/cryptolex-official",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "28",
    imageSrc: Img28,
    imageWidth: 300,
    imageHeight: 300,
    title: "GODDESS IRIS",
    date: "",
    description: "Launched",
    socials: [
      {
        src: "https://www.irisnft.io/",
        icon: "fas fa-external-link-alt",
      },
      {
        src: "https://opensea.io/collection/goddess-iris-v2-1",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "12",
    imageSrc: Img12,
    imageWidth: 300,
    imageHeight: 300,
    title: "SUWC-Swiss Unique Watch Club",
    date: "",
    description: "Launched",
    socials: [
      {
        src: "https://opensea.io/collection/suwc-swiss-unique-watch-club",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "29",
    imageSrc: Img29,
    imageWidth: 300,
    imageHeight: 300,
    title: "Flookimooni",
    date: "",
    description: "Launched",
    socials: [
      {
        src: "https://www.flokimooni.com",
        icon: "fas fa-external-link-alt",
      },
    ],
  },
  {
    id: "34",
    imageSrc: Img34,
    imageWidth: 300,
    imageHeight: 300,
    title: "The Crypto Paradox Club",
    date: "",
    description: "Upcoming",
    socials: [
      {
        src: "https://cryptoparadoxclub.com/",
        icon: "fas fa-external-link-alt",
      },
    ],
  },
  {
    id: "3",
    imageSrc: Img3,
    imageWidth: 300,
    imageHeight: 300,
    title: "Crypto Crewz",
    date: "",
    description: "Upcoming",
    socials: [
      {
        src: "https://www.cryptocrewz.com/pages/bm-nft",
        icon: "fas fa-external-link-alt",
      },
    ],
  },
  {
    id: "14",
    imageSrc: Img14,
    imageWidth: 300,
    imageHeight: 300,
    title: "CryptoHippos",
    date: "",
    description: "Sold Out",
    socials: [
      {
        src: "https://cryptohipponft.com/",
        icon: "fas fa-external-link-alt",
      },
      {
        src: "https://opensea.io/collection/cryptohipposnft",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "35",
    imageSrc: Img35,
    imageWidth: 300,
    imageHeight: 300,
    title: "Calabasas Cave Creatures",
    date: "",
    description: "Upcoming",
    socials: [
      {
        src: "https://cavecreatures.io/",
        icon: "fas fa-external-link-alt",
      },
    ],
  },
  {
    id: "36",
    imageSrc: Img36,
    imageWidth: 300,
    imageHeight: 300,
    title: "The blind club",
    date: "",
    description: "Upcoming",
    socials: [
      {
        src: "https://linktr.ee/theblindclub",
        icon: "fas fa-external-link-alt",
      },
    ],
  },
  {
    id: "4",
    imageSrc: Img4,
    imageWidth: 300,
    imageHeight: 300,
    title: "Mystical Muses NFT",
    date: "",
    description: "Launched",
    socials: [
      {
        src: "https://mysticalmuses.io/",
        icon: "fas fa-external-link-alt",
      },
      {
        src: "https://opensea.io/collection/mysticalmusesnft",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "54",
    imageSrc: Img17,
    imageWidth: 300,
    imageHeight: 300,
    title: "Dangerous Dinos",
    date: "",
    description: "Upcoming",
    socials: [
      {
        src: "https://www.dangerousdinos.io/",
        icon: "fas fa-external-link-alt",
      },
    ],
  },
  {
    id: "38",
    imageSrc: Img38,
    imageWidth: 300,
    imageHeight: 300,
    title: "Modoverse",
    date: "",
    description: "Upcoming",
    socials: [],
  },
  {
    id: "9",
    imageSrc: Img9,
    imageWidth: 300,
    imageHeight: 300,
    title: "Armed Bears",
    date: "",
    description: "Upcoming",
    socials: [],
  },
  {
    id: "19",
    imageSrc: Img19,
    imageWidth: 300,
    imageHeight: 300,
    title: "Crypto Sports Fans",
    date: "",
    description: "Upcoming",
    socials: [],
  },
  {
    id: "46",
    imageSrc: Img46,
    imageWidth: 300,
    imageHeight: 300,
    title: "Space Samurai",
    date: "",
    description: "Launched",
    socials: [
      {
        src: "https://spacesamurai.io/",
        icon: "fas fa-external-link-alt",
      },
      {
        src: "https://opensea.io/collection/space-samurai-club",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "47",
    imageSrc: Img47,
    imageWidth: 300,
    imageHeight: 300,
    title: "AristoJag",
    date: "",
    description: "Upcoming",
    socials: [],
  },
  {
    id: "8",
    imageSrc: Img8,
    title: "Racoonverse",
    date: "",
    imageWidth: 300,
    imageHeight: 300,
    description: "Upcoming",
    socials: [],
  },
  {
    id: "48",
    imageSrc: Img48,
    title: "Rebel Tiger Club",
    date: "",
    imageWidth: 300,
    imageHeight: 300,
    description: "Launched",
    socials: [
      {
        src: "https://opensea.io/collection/rebeltiger",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "49",
    imageSrc: Img49,
    title: "Kangaroo Fight Club",
    date: "",
    imageWidth: 300,
    imageHeight: 300,
    description: "Upcoming",
    socials: [],
  },
  {
    id: "41",
    imageSrc: Img41,
    title: "Gifted Tigers NFT",
    date: "",
    imageWidth: 300,
    imageHeight: 300,
    description: "Upcoming",
    socials: [
      {
        src: "https://giftedtigers.io/",
        icon: "fas fa-external-link-alt",
      },
    ],
  },
  {
    id: "42",
    imageSrc: Img42,
    title: "Boss Chicks",
    date: "",
    imageWidth: 300,
    imageHeight: 300,
    description: "Upcoming",
    socials: [
      {
        src: "https://twitter.com/BossChicksNft",
        icon: "fa-brands fa-twitter",
      },
    ],
  },
  {
    id: "43",
    imageSrc: Img43,
    title: "Squirrels FLow",
    date: "",
    imageWidth: 300,
    imageHeight: 300,
    description: "Upcoming",
    socials: [
      {
        src: "https://sqrlsflow.com/",
        icon: "fas fa-external-link-alt",
      },
    ],
  },
  {
    id: "44",
    imageSrc: Img44,
    title: "Ninja Clan NFT",
    date: "",
    imageWidth: 300,
    imageHeight: 300,
    description: "Upcoming",
    socials: [
      {
        src: "https://opensea.io/collection/ninja-clan",
        icon: "fa-opensea",
      },
    ],
  },
  {
    id: "51",
    imageSrc: Img51,
    title: "Dabookie",
    date: "",
    imageWidth: 300,
    imageHeight: 300,
    description: "Upcoming",
    socials: [
      {
        src: "https://dabookienft.com/",
        icon: "fas fa-external-link-alt",
      },
    ],
  },
  {
    id: "50",
    imageSrc: Img50,
    title: "Football Ape",
    date: "",
    imageWidth: 300,
    imageHeight: 300,
    description: "Upcoming",
    socials: [],
  },
];

export default fakeCollectionData;
