import {useState} from "react";
import { useLocation } from 'react-router-dom'
import styles from '../styles/signup.module.scss'
import InputBox from "../components/InputBox";
import Button from "../components/Button";
import Dropdown from "../components/Dropdown";
import {roles} from "../utils";
import Sign from '../assets/signup.png'


function SignUp() {
    const { query } = useLocation();
    const [username, setUsername] = useState(() => '');
    const [password, setPassword] = useState(() => '');
    const [selectedRole, setSelectedRole] = useState(query?.role || '');
    const [agreement, setAgreement] = useState(false);

    const handleFormSubmit = () => {}

    return (
        <section className={styles.container}>
            <div className={styles.form_container}>
                <div>
                    <div className={styles.icon_wrapper}>
                        <div className={styles.icon}>
                            <i className="far fa-user"></i>
                        </div>
                        <h3>Register & Join over 20k Creators</h3>
                    </div>
                    <h6 className='ff-regular fw-normal text-medium-dark'>
                        Let’s start your Experience and roin ex erat,
                        pulvinar quis tempus.
                    </h6>
                </div>

                <h3 className='mb-5'>Let’s start</h3>
                <form className={styles.form}>
                    <div>
                        <Dropdown
                            content={roles}
                            selectedItem={selectedRole}
                            setSelectedItem={setSelectedRole}
                            wrapperClassName={'mb-5'}
                        />
                    </div>
                    <InputBox
                        type='text'
                        label='Full Name'
                        name='name'
                        inputId='name'
                        onChange={(value) => setUsername(() => value)}
                        required
                        wrapperClassname='mb-5'
                    />
                    <InputBox
                        type='text'
                        label='Nickname / Login *'
                        name='username'
                        inputId='username'
                        onChange={(value) => setUsername(() => value)}
                        wrapperClassname='mb-5'
                    />
                    <InputBox
                        type='email'
                        label='Email *'
                        name='email'
                        inputId='email'
                        onChange={(value) => setUsername(() => value)}
                        wrapperClassname='mb-5'
                    />
                    <InputBox
                        type='password'
                        label='Password'
                        name='password'
                        inputId='pass'
                        rightIcon
                        onChange={(value) => setPassword(() => value)}
                        wrapperClassname='mb-5'
                    />
                    <InputBox
                        type='text'
                        label='LinkedIn'
                        name='email'
                        inputId='email'
                        onChange={(value) => setUsername(() => value)}
                        wrapperClassname='mb-5'
                    />
                    <InputBox
                        type='text'
                        label='Portfolio Link *'
                        name='portfolio'
                        inputId='portfolio'
                        onChange={(value) => setUsername(() => value)}
                        wrapperClassname='mb-5'
                    />
                    <div className={styles.additional_info}>
                        <div className={styles.remember_user}>
                            <input id='rememberUser' type="checkbox" defaultChecked={agreement}
                                   onChange={() => setAgreement(() => !agreement)}/>
                            <span className={styles.custom_checkbox} />
                            <label htmlFor="rememberUser">I Agree all Terms&Conditions</label>
                        </div>
                        <Button
                            type='submit'
                            onSubmit={handleFormSubmit}
                            text='Register'
                        />
                    </div>

                </form>
            </div>
            <div className={styles.image_wrapper}>
                <img
                    src={Sign}
                />
            </div>
        </section>
    )
}

export default SignUp