export const socialData = [
    {
        id: 'facebook_1',
        icon: 'fab fa-facebook-f',
        link: 'https://www.facebook.com/odd.republic'
    },
    {
        id: 'twitter_1',
        icon: 'fab fa-twitter',
        link: 'https://twitter.com/Odd_Republic1'
    },
    {
        id: 'insta_1',
        icon: 'fab fa-instagram',
        link: 'https://www.instagram.com/odd_republic'
    }
]


export const getDateDiffs = (date) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date(date);
    const currentDate = new Date();
    const diffDays = Math.round(Math.abs((firstDate - currentDate) / oneDay));
    const diffTime = (Math.abs(firstDate - currentDate) / (1000 * 60 * 60)).toFixed(1);
    return {
        diffDays,
        diffTime
    };
}

export const roles = ['freelancer', 'client', 'project manager'];