import styles from "../styles/staticPages.module.scss";

const PrivacyPolicy = () => (
    <div className={styles.container}>
        <h2>1. Introduction</h2>
        <p>
            Welcome to the<span className="color--primary"> ODD Republic</span> website (“Site”). <span className="color--primary">ODD Republic</span> LLC (<b className="color--primary">“ODD Republic”</b>,
            “we”, “us”, and/or “our”) operates this Site to provide our users (“you” and “your”) with
            information about <span className="color--primary">ODD Republic</span> and its platform. <br/><br/>
            “ODD Republic is used by professional freelancers (<b>“Freelancers”</b>) who wish to offer
            their development, engineering and other services (<b>“Services”</b>) and clients who need
            the Services (<b>“Clients”</b>). Freelancers that have passed our screening process and are
            admitted to our network of professional Freelancers (<b>“Network”</b>) are referred to as
            <b>“Talent”</b>. For purposes of this policy the term “Freelancers” is broader in scope and
            includes both Talent and Freelancers who are not Talent. The Personal Data we receive
            from Freelancers and Clients and how we handle it differs, so we explain our data
            practices for Freelancers and Clients in two distinct sections below. Click on the one
            that applies to you: <a href="#freelancers">Freelancers</a>; <a href="#clients">Clients</a>.
        </p>
        <hr className={styles.divider} />
        <h2>2. Scope</h2>
        <p>
            This Privacy Policy sets out how <span className="color--primary"> ODD Republic</span> collects, retains, and uses information,
            including personal data (<b>“Personal Data”</b>), about Freelancers, Clients, other Site
            visitors and individuals, including our vendors, partners, blog readers, and job
            applicants, as well as users of <span className="color--primary"> ODD Republic</span> software offerings such as our image
            sharing and editing tool. This Privacy Policy also covers data that <span className="color--primary"> ODD Republic</span>
            collects in-person, for instance at business conferences and trade shows.
        </p>
        <hr className={styles.divider} />

        <h2 id='freelancers'>3. Freelancers</h2>
        <h3>3.1 Information We Collect</h3>
        <h4><b>A.Information That You Provide To Us</b></h4>
        <p>
            When you apply as a Freelancer on the Site, you are asking to be admitted to our
            Network. In order to admit you to the Network and match your qualifications with our
            Client’s needs, we need to perform a screening process, which evaluates your language
            skills, personality, domain-specific knowledge, competence and professionalism. We
            may also need to screen potential Freelancers for specific technical skills, as required
            by our Clients. In this context, you will be asked to provide information about yourself,
            which can includes:
        </p>
        <ul>
            <li>your name</li>
            <li>contact information</li>
            <li>address</li>
            <li>location</li>
            <li>work experience</li>
            <li>education</li>
            <li>photo</li>
            <li>government-issued identification</li>
            <li>social network information</li>
            <li>billing information</li>
        </ul>
        <hr className={styles.divider} />
        <h5><b>Verifying Your Identity</b></h5>
        <p>
            <span className='color--primary'>
                If you are asking to be admitted to our Network, with your explicit written informed
                consent (which you may provide at the time of submitting a request to be admitted) we
                may collect biometric information about you to verify and confirm your identity and as
                further described below. We may ask you to take a photo of yourself and of your
                government-issued identification through your mobile phone (the <b>“Verification Data”</b>).
                The Verification Data will be provided to our support team, who will use the photographs
                and extract biometric information or biometric identifiers relating to you (collectively,
                <b>“Biometric Data”</b>). The Verification Data and the Biometric Data will be used to verify
                and confirm your identity.
            </span>
            <br/><br/>
            Consenting to the collection and processing of your Biometric Data and your Verification
            Data as described in this Privacy Policy is voluntary and if you do not consent to the
            collection of your Biometric Data and Verification Data, we will allow you to provide us
            your government-issued identification and confirm your identity via video conference,
            and may ask you to provide us with a photo of yourself. <span className="color--primary"> ODD Republic</span> will retain your
            self-photo and your name for fraud prevention purposes based upon our legitimate
            interests. If you consented to the collection of your Biometric Data and Verification Data,
            but later change your mind, you have the right to withdraw your consent by contacting
            us at <a href="mailto:privacy@OddRepublic.io" className={styles.mail}>privacy@OddRepublic.io</a>. If you withdraw your consent, we will apply your
            preferences going forward and this will not affect the lawfulness of the processing of
            your Biometric Data or Verification Data prior to the effective date of your consent
            withdrawal.
        </p>
        <br/><br/><br/>
        <h5><b>Background Check</b></h5>
        <p>
            Our provider of background check services may collect additional data about you
            (including information about criminal convictions or offences) if and when we undertake
            vetting/background checks. If applicable to you, your consent to the collection of such
            additional data will be solicited at such time, pursuant to applicable laws.
        </p>
        <h5><b>Information You Share</b></h5>
        <p>
            When you participate in the community of <span className="color--primary"> ODD Republic</span>’s experts on our Site, we will
            collect the information that you elect to share in that context.
        </p>
        <h5><b>Information You Share</b></h5>
        <p>
            When you participate in the community of <span className="color--primary"> ODD Republic</span>’s experts on our Site, we will
            collect the information that you elect to share in that context.
        </p>
        <h5><b>Profile Creation</b></h5>
        <p>
            We will use certain of your Personal Data to enable you to create a profile to be
            displayed within the Services; this profile is searchable and may be viewed by Clients
            seeking Freelancers.
        </p>
        <h5><b>Client Feedback</b></h5>
        <p>
            Once you are admitted to the Network and you are matched with a Client, we will
            receive from our Clients information regarding the performance of your services to that
            Client, as necessary for maintaining standards of integrity and excellence in our
            Network.
        </p>
        <br/><br/>
        <h4><b>B. Automatically Collected Data</b></h4>
        <p>When you interact with <span className="color--primary"> ODD Republic</span> through the Site or services, we collect
            automatically certain information. Please read the <a href="#visitors"><b>“Site Visitors”</b></a>
            section below for more information.</p>
        <hr/>

        <h3>3.2. How We Use the Information</h3>
        <h4><b>A. To Provide The Service And Respond To Requests</b></h4>
        <p>
            <span className="color--primary">ODD Republic</span> uses the Personal Data you provide in a manner that is consistent with
            this Privacy Policy. If you provide Personal Data for a certain reason, we will use the
            Personal Data in connection with the reason for which it was provided. <br/><br/>
            In particular, we use Personal Data that you provide to us (described above) in order to
            process your request to become a Freelancer and administer our contract with you once
            you become a member of the Network. For individuals, our use of Personal Data that
            you provide to us is necessary in order to implement your request to become a
            Freelancer prior to entering into a contract with us and, once you are admitted to our
            Network, to perform our contract with you.
        </p>
        <br/><br/><br/>
        <h4><b>B. As Necessary For Certain Legitimate Interests</b></h4>
        <p>We use your Personal Data for the legitimate interests described below:</p>
        <ul>
            <li>
                To send administrative information to you, for example, information regarding
                the Site and changes to our terms, conditions, and policies.
            </li>
            <li>
                To conduct analytics on how the Site and our Service are being used by you
                for our internal purposes, namely for providing, maintaining, benchmarking
                and improving our offerings, and identifying usage trends.
            </li>
            <li>
                We use data relating to your use of and interaction with the Site and the
                Services, including use of product features by you and information that we
                obtain through cookies and other technologies, to better understand your
                needs and interests in order to personalize your experience with our Services
            </li>
            <li>
                To market our services and showcase Freelancers (in particular, we can
                feature certain profiles of Freelancers to drive traffic from potential Clients).
            </li>
            <li>
                To prevent fraud or criminal activity, misuses of our products or services, and
                ensure the security of our IT systems, architecture and networks.
            </li>
            <li>
                To (a) comply with legal obligations and legal process, (b) respond to requests
                from public and government authorities including public and government
                authorities outside your country of residence; (c) enforce our terms and
                conditions; (d) protect our operations; (e) protect our rights, privacy, safety or
                property, and/or that of you or others; and (f) allow us to pursue available
                remedies or limit the damages that we may sustain.
            </li>
            <li>
                If you ask us to delete your data and we are required to fulfill your request, to
                keep basic data to identify you and prevent further unwanted processing.
            </li>
        </ul>
        <h4><b>C. With your freely given, informed consent</b></h4>
        <p>
            If you participate in a video conference, with your prior consent, the conference may be
            recorded and transcribed. Consenting is voluntary, and you may withdraw your consent
            at any time. Information collected may include your voice, image, in-meeting messages,
            and any other Personal Data you choose to share. In addition, participants in a video
            conference may be able to view your display name, email address and profile picture.
            By using conference services provided <a href="https://zoom.us/" target='_blank' rel='noreferrer'>by Zoom Video Communications, Inc. (“Zoom”)</a>,
            Zoom may collect, use and share additional personal data pertaining to you, which will
            be used in accordance with Zoom’s Privacy Statement.
        </p>
        <hr className={styles.divider}/>
        <h2 id='clients'>4. Clients</h2>
        <h3>4.1 Information We Collect</h3>
        <h4><b>A.Information That You Provide To Us</b></h4>
        <p>We collect Personal Data from our prospective Clients when we receive a request for
            hiring our Freelancers. This includes names and contact information of prospective
            Client’s representatives, and any information relating to the Client’s needs.</p>
        <h4><b>B. Information Obtained From Third Parties</b></h4>
        <p>We may supplement the information that we collected from you (such as your email
            address) with additional information about you and your company obtained from publicly
            available and third-party databases or services that provide information about business
            people and companies (including an individual’s name, job title, business contact
            information, and company information).</p>
        <h4><b>C. Automatically Collected Data</b></h4>
        <p>
            When you interact with ODD Republic through the Site or services, we automatically
            collect certain information. Please read the <a href="#visitors">“Site Visitors”</a> section below for
            more information.
        </p>
        <h3>4.2 How We Use the Information</h3>
        <h4><b>A.To Provide The Services And Respond To Requests</b></h4>
        <p>
            <span className="color--primary">ODD Republic</span> uses the Personal Data you provide in a manner that is consistent with
            this Privacy Policy. If you provide Personal Data for a certain reason, we may use the
            Personal Data in connection with the reason for which it was provided. For instance, if
            you contact us by email, we will use the Personal Data you provide to answer your
            question or resolve your problem. Also, if you provide Personal Data in order to obtain
            access to the Services, we will use your Personal Data to provide you with access to
            such Services, maintain your account, contact you regarding your use of the Services
            and/or the Site or to notify you of important changes to the Services and/or the Site, and
            to monitor your use of such services.
        </p>
        <h4><b>B. For Marketing Purposes</b></h4>
        <p>
            We may use your contact details to tell you about services we believe will be of interest
            to you, upcoming events or other promotions. If we do so, each marketing
            communication we send you will contain instructions permitting you to “opt out” of
            receiving future marketing communications. Note, however, that as a user of our
            services you cannot opt out of some administrative communications that are reasonably
            necessary to the services, such as billing or service notifications. In addition, if at any
            time you wish not to receive any future marketing communications or you wish to have
            your name deleted from our mailing lists, please contact us as indicated below. <br/><br/>
            Where required by applicable law, we will only send you marketing information by email
            if you consent to us doing so at the time you provide us with your Personal Data. When
            you provide us with your consent to be contacted for marketing purposes, you have the
            right to withdraw your consent at any time by following the instructions to “opt-out” of
            receiving marketing communication in each marketing email we send you or by
            contacting us as indicated below.
        </p>
        <h4><b>C. As Necessary For Certain Legitimate Interests</b></h4>
        <p>We use your Personal Data for the legitimate interests described below:</p>
        <ul>
            <li>
                To send administrative information to you, for example, information regarding
                the Site and changes to our terms, conditions, and policies.
            </li>
            <li>
                To respond to your inquiries and fulfill your requests, such as to send you
                requested materials and newsletters, as well as information and materials
                regarding our products and services.
            </li>
            <li>
                To conduct analytics on how the Site and our Service are being used by you
                for our internal purposes (namely for providing, maintaining, benchmarking
                and improving our offerings, identifying usage trends and determining the
                effectiveness of our promotional campaigns) and to inform our marketing
                strategy and personalize our communications with you (including providing
                information on our features and other marketing and service-related
                announcements relevant to the content and features you engage with).
            </li>
            <li>
                To supplement the information that we collected from you with information
                obtained from third parties (described above) in order to update, expand and
                analyze our records, identify new Clients, and provide products and services
                that may be of interest to you.
            </li>
            <li>
                To prevent fraud or criminal activity, misuses of our products or services, and
                ensure the security of our IT systems, architecture and networks.
            </li>
            <li>
                To (a) comply with legal obligations and legal process, (b) respond to requests
                from public and government authorities including public and government
                authorities outside your country of residence; (c) enforce our terms and
                conditions; (d) protect our operations; (e) protect our rights, privacy, safety or
                property, and/or that of you or others; and (f) allow us to pursue available
                remedies or limit the damages that we may sustain.
            </li>
            <li>
                If you ask us to delete your data and we are required to fulfill your request, to
                keep basic data to identify you and prevent further unwanted processing.
            </li>
        </ul>
        <h4><b>D. With your freely given, informed consent</b></h4>
        <p>
            If you participate in a video conference, with your prior consent, the conference may be
            recorded and transcribed. Consenting is voluntary, and you may withdraw your consent
            at any time. Information collected may include your voice, image, in-meeting messages,
            and any other Personal Data you choose to share. In addition, participants in a video
            conference may be able to view your display name, email address and profile picture.
            By using conference services provided <a href="https://zoom.us/" target='_blank' rel='noreferrer'>by Zoom Video Communications, Inc. (“Zoom”)</a>,
            Zoom may collect, use and share additional personal data pertaining to you, which will
            be used in accordance with Zoom’s Privacy Statement. <br/><br/>
            If <span className="color--primary">ODD Republic</span> intends on using any Personal Data in any manner that is not
            consistent with this Privacy Policy, you will be informed of such anticipated use prior to
            or at the time at which the Personal Data is collected and pursuant to the applicable
            law.
        </p>
        <hr className={styles.divider}/>
        <h2 id='applicants'>5. Job Applicants</h2>
        <h3>5.1 Information We Collect</h3>
        <h4><b>A.Application and assessment process</b></h4>
        <p>
            When you apply for employment through our Site, our provider of recruiting services will
            collect the Personal Data that you provide in your resume and cover letter. It is for you
            to decide what you include in these documents. However, the kinds of information you
            may wish to include are: your name, contact details, employment history and education.
            If you are invited to undertake further assessments (such as an interview) in connection
            with your application and you participate in such assessments, we will collect further
            Personal Data that you provide to us as part of that process. The kinds of information
            you may disclose include information about your qualifications and information about
            your employment experience. You may choose to disclose salary history or salary
            expectations. If you do not provide us with certain information when requested, it may
            impact our ability to assess your suitability for a role with us or we may not be able to
            make you an offer of employment. With your prior consent, we may record your
            interview(s) to better evaluate your candidacy and for training purposes. Your consent is
            voluntary, and you may withdraw your consent at any time, including during the
            interview. If you withdraw your consent or do not consent, it will have no impact on your
            candidacy.
            <br/>
            Throughout the recruitment process, we may create Personal Data in connection with
            the assessment of your application. For example, we may record the views of those
            considering your application about your suitability for the role for which you have applied and retain interview notes. <br/>
            We have a legitimate interest in facilitating the interview process and communicating
            offers of employment to you, making informed recruitment decisions and selecting
            suitable candidates for roles with us, and improving our recruiting and hiring processes.
            <br/>
            We will retain your contact details and resume to inform you of current and future career
            opportunities (unless you tell us that you do not want us to keep your details for that
            purpose) pursuant to our legitimate interest in doing so.
        </p>
        <h4><b>B.If your application is successful</b></h4>
        <p>
            If your application is successful we will collect further Personal Data about you in
            connection with conducting reference and background checks where required or
            permitted by applicable local law (including if applicable to you special categories of
            Personal Data, and your consent to the collection of such additional data will be
            solicited at such time, pursuant to applicable laws). We will also collect copies of
            relevant identification documents from you (such as your passport or driving license,
            proof of address, a copy of your work permit (where applicable), a photograph and a
            copy of your signature). We use this information to comply with immigration
            requirements and to verify your identity for our own internal security purposes. We have
            a legitimate interest in collecting and using this data to comply with our legal obligations
            and for the performance of your employment contract with us.
            <br/>
            If you are hired, your Personal Data will be used as part of your employee record under
            our employee privacy policies.
        </p>
        <h4><b>C.Automatically Collected Data</b></h4>
        <p>
            When you interact with <span className="color--primary">ODD Republic</span> through our Site in relation to your application,
            we collect automatically certain information. Please read the “Site Visitors” section
            below for more information.
        </p>
        <hr className={styles.divider}/>
        <h2 id='visitors'>6. Site Visitors</h2>
        <p>
            When you interact with <span className="color--primary">ODD Republic</span> through the Site or the Services (whether as a
            Freelancer, as a Client, a job applicant, a blog reader, software end user, or other visitor to our Site), we and our service providers acting on our behalf will automatically collect
            information about you through cookies (small text files placed on your device) and other
            technologies. Please see our <a href="#cookie">Cookie Policy</a> to learn more about how we use
            cookies and other technologies.
            <br/><br/>
            As a visitor to our Site, our servers record information (“log data”), including information
            that your browser automatically sends whenever you visit the Site. This log data
            includes your Internet Protocol (“IP”) address (from which we understand the country
            you are connecting from at the time you visit the Site), browser type and settings, the
            date and time of your request.
            <br/><br/>
            Where the information that we collect automatically on our Site is Personal Data, our
            legal basis for the use of this information is that it is necessary for the purposes of our
            legitimate interests in maintaining the safe operation of our Site as well as in learning
            how Site visitors interact with our Site to improve your use of it.
        </p>
        <hr className={styles.divider}/>
        <h2 id='software'>7. Software end users</h2>
        <p>
            When you use <span className="color--primary">ODD Republic</span> software, we will collect account level data (your name,
            email and password) for access, storage of historical data, and operation of software. If
            you create an account by using credentials from a third party service or sign-in services
            such as your Google account, these services will authenticate your identity and provide
            you with the option to share certain Personal Data, such as name and email
            address(es), with us. Use of account level data is necessary to perform a contract with
            us in relation to your use of the software or it is in our legitimate interest to respond to
            your requests and ensure we provide our services in the best way that we can.
            <br/>
            When you use the software, we may collect usage statistics for analytics and to support
            and troubleshoot the software to give you a better user experience. We have a
            legitimate interest in conducting usage statistics to ensure our software is working as
            intended and to make improvements to our services. We use service providers to assist
            in authentication of users and cloud hosting the storage and retrieval of screenshots.
            <br/>
            <span className="color--primary">ODD Republic</span>’s software has online components and cookies are required for full
            functionality. For more information on cookie usage please see the Cookie Policy.
        </p>
        <hr className={styles.divider}/>
        <h2 id='events'>8. Events</h2>
        <p>
            We may collect Personal Data from you when you attend one of our events, for instance
            at business conferences and trade shows. The data we collect includes information that
            you voluntarily give to us, such as your business card or contact details. We will use this
            information to develop a business relationship and in a manner consistent with the
            purpose you gave the information for.
            <br/><br/>
            Where required by applicable law we will ask your consent before or when sending you
            marketing information by email. When you provide us with your consent to be contacted
            for marketing purposes, you have the right to withdraw your consent at any time by
            following the instructions to “opt-out” of receiving marketing communication in each
            marketing email we send you or by contacting us as indicated below.
        </p>
        <hr className={styles.divider}/>
        <h2 id='disclosure'>9. Our Disclosure of Your Personal Data and  Other Information</h2>
        <p>
            <span className="color--primary">ODD Republic</span> is not in the business of selling your information. We consider this
            information to be a vital part of our relationship with you. There are, however, certain
            circumstances in which we may share your Personal Data with certain third parties, as
            set forth below:
        </p>
        <h4>Business Transfers</h4>
        <p>
            As we develop our business, we might sell or buy businesses or assets. In the event of
            a corporate sale, merger, reorganization, dissolution or similar event, Personal Data
            may be part of the transferred assets.
        </p>
        <h4>Agents, Consultants and Other Service Providers</h4>
        <p>
            <span className="color--primary">ODD Republic</span>, like many businesses, sometimes hires other companies to perform
            certain business-related functions. These parties include website analytics companies,
            providers of digital advertising services, our hosting and cloud computing service
            providers, providers of CRM, marketing and sales software solutions, providers of billing
            and processing payments functions, providers of background check services. When we
            employ another company to perform a function of this nature, we only provide them with
            the information that they need to perform their specific function. Pursuant to our
            instructions, these parties may access, process or store Personal Data in the course of
            performing their duties to us and solely in order to perform the services we have hired
            them to provide.
        </p>
        <h4>Legal Requirements</h4>
        <p>
            <span className="color--primary">ODD Republic</span> may disclose your Personal Data if required to do so by law or in the
            good faith belief that such action is necessary to (i) comply with a legal obligation, (ii)
            protect and defend the rights or property of <span className="color--primary">ODD Republic</span>, (iii) act in urgent
            circumstances to protect the personal safety of users of the Site or the public, or (iv)
            protect against legal liability.
        </p>
        <hr className={styles.divider}/>
        <h2 id='rights'>10. Your Rights</h2>
        <p>
            Subject to applicable <span className="color--primary">Armenian</span> law, you have the following rights in relation to your
            Personal Data:
        </p>
        <ul>
            <li>
                <b>Right of access.</b> If you ask us, we will confirm whether we are processing
                your Personal Data and, if so, provide you with a copy of that Personal Data
                along with certain other details. If you require additional copies, we may need
                to charge a reasonable fee.
            </li>
            <li>
                <b>Right to rectification.</b> If your Personal Data is inaccurate or incomplete, you
                are entitled to ask that we correct or complete it. If we shared your Personal
                Data with others, we will tell them about the correction where possible. If you
                ask us, and where possible and lawful to do so, we will also tell you with whom
                we shared your Personal Data so you can contact them directly.
            </li>
            <li>
                <b>Right to erasure.</b> You may ask us to delete or remove your Personal Data,
                such as where you withdraw your consent. If we shared your data with others,
                we will tell them about the erasure where possible. If you ask us, and where
                possible and lawful to do so, we will also tell you with whom we shared your
                Personal Data with so you can contact them directly.
            </li>
            <li>
                <b>Right to restrict processing.</b> You may ask us to restrict or ‘block’ the
                processing of your Personal Data in certain circumstances, such as where you
                contest the accuracy of the data or object to us processing it. We will tell you
                before we lift any restriction on processing. If we shared your Personal Data
                with others, we will tell them about the restriction where possible. If you ask
                us, and where possible and lawful to do so, we will also tell you with whom we
                shared your Personal Data so you can contact them directly.
            </li>
            <li>
                <b>Right to data portability.</b> You have the right to obtain your Personal Data
                from us that you consented to give us or that was provided to us as necessary
                in connection with our contract with you. We will give you your Personal Data
                in a structured, commonly used and machine-readable format. You may reuse
                it elsewhere.
            </li>
            <li>
                <b>Right to object.</b> You may ask us at any time to stop processing your Personal
                Data, and we will do so: (1) If we are relying on a legitimate interest to process
                your Personal Data – unless we demonstrate compelling legitimate grounds
                for the processing or (2) If we are processing your Personal Data for direct
                marketing.
            </li>
            <li>
                <b>Rights in relation to automated decision-making and profiling.</b> You have
                the right to be free from decisions based solely on automated processing of
                your Personal Data, including profiling, which produce a significant legal effect
                on you, unless such profiling is necessary for entering into, or the performance
                of, a contract between you and us, or with your explicit consent. We are not
                currently processing your Personal Data for such type of automated decision-
                making, including profiling, but if we elect to do so in the future we will provide
                you with notice and choice, in accordance with <span className='color--primary'>Armenian</span> data protection law
            </li>
            <li>
                <b>Right to withdraw consent.</b> If we rely on your consent to process your
                Personal Data, you have the right to withdraw that consent at any time, but
                this will not affect any processing of your data that has already taken place.
            </li>
            <li>
                <b>Right to lodge a complaint with the data protection authority.</b> If you have
                a concern about our privacy practices, including the way we handled your
                Personal Data, you can report it to the data protection authority that is
                authorized to hear those concerns.
            </li>
        </ul>
        <p>
            You may exercise your rights by contacting us as indicated under “Contacting <span className="color--primary">ODD Republic</span>” section below.
        </p>
        <h3>Legitimate Interest</h3>
        <p>
            “Legitimate interests” means our interests in conducting and managing our organization
            and delivering the best Services to you. This Privacy Policy describes when we process
            your Personal Data for our legitimate interests, what these interests are and your rights.
            We will not use your Personal Data for activities where the impact on you overrides our
            interests, unless we have your consent or those activities are otherwise required or
            permitted by law. You have the right to object to processing that is based on our
            legitimate interests. For more information on your rights, please see “Your Rights”
            section above.
        </p>
        <h3>
            Data Retention
        </h3>
        <p>
            Our policy is to keep your Personal Data only for as long as is reasonably necessary to
            fulfill the purposes for which it was collected and processed, including for the purposes
            of satisfying any legal, regulatory, accounting or reporting requirements. If you have
            elected to receive marketing communications from us, we retain information about your
            marketing preferences until you opt out of receiving these communications and in
            accordance with our policies.
            <br/><br/>
            To determine the appropriate retention period for your Personal Data, we will consider
            the amount, nature, and sensitivity of the Personal Data, the potential risk of harm from
            unauthorized use or disclosure of your Personal Data, the purposes for which we use
            your Personal Data and whether we can achieve those purposes through other means,
            and the applicable legal requirements. In some circumstances we may anonymize your
            Personal Data so that it can no longer be associated with you, in which case it is no
            longer Personal Data.
        </p>
        <hr className={styles.divider}/>
        <h2 id='choices'>11. Your Choices</h2>
        <p>You can use the Site without providing any Personal Data. If you choose not to provide
            any Personal Data, you may not be able to use certain services.</p>
    </div>
)

export default PrivacyPolicy;