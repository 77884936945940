import { Routes, Route } from "react-router-dom";
import Home from "./pages";
import About from "./pages/about";
import Freelancers from "./pages/freelancers";
import OurWork from "./pages/ourWork";
import PrivacyPolicy from "./pages/privacyPolicy";
import Signup from "./pages/signup";
import PolicyImages from './pages/policyImages';
import TermsAndConditions from "./pages/termsAndConditions";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "@fortawesome/fontawesome-free/js/all";
import "swiper/css";
import "swiper/css/navigation";
import "./styles/globals.scss";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="freelancers" element={<Freelancers />} />
        <Route path="our-work" element={<OurWork />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="sign-up" element={<Signup />} />
        <Route path="regulations" element={<TermsAndConditions />} />
        <Route path="privacy-policy/nda/logo" element={<PolicyImages/>} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
