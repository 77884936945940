import { useState, useRef, useCallback } from "react";
import styles from './inputBox.module.scss';
import cn from 'classnames'

const InputBox = ({
    type,
    label,
    name,
    inputId,
    placeholder,
    rightIcon,
    onChange,
    disabled,
    required,
    wrapperClassname
}) => {
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState('');
    const [focused, setFocused] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isFullfilled, setIsFullfilled] = useState(false);

    const onBlur = useCallback((e) => {
        if(!inputValue){
            setFocused(false)
            return;
        }
        setIsFullfilled(true);
    }, [inputValue])

    const onFocus = useCallback((e) => {
        setFocused(true)
    }, [])

    const onInputChange = useCallback((e) => {
        setInputValue(e.currentTarget.value);
        onChange(e.currentTarget.value)
    }, [])

    const handleMouseDown = useCallback(() => {
        if(type === 'password') {
            inputRef.current.type = 'text'
        }
    }, [type])

    const handleMouseUp = useCallback(() => {
        if(type === 'password') {
            inputRef.current.type = 'password'
        }
    }, [type])

    return (
        <div className={cn(styles.wrapper, wrapperClassname)}>
            <input
                ref={inputRef}
                type={type}
                name={name}
                value={inputValue}
                placeholder={placeholder}
                className={styles.input}
                onChange={onInputChange}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={disabled}
                required={required}
            />
            <label
                className={`${styles.label} ${cn({
                    [styles.focused]: focused,
                    [styles.error]: isValid,
                    [styles.fullfilled]: isFullfilled,
                })}`}
                htmlFor={inputId}
            >
                {label}
            </label>
            {rightIcon && (
                <button
                    type='button'
                    className={styles.iconBtn}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                >
                    <i className={rightIcon} />
                </button>
            )}
        </div>
    )
}

export default InputBox