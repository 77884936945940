import styles from "./portfolioItem.module.scss";

const PortfolioItem = ({imageSrc,imageWidth, imageHeight, socialStatus, description, title}) => {
    return (
        <div className={styles.portfolio_item}>
            <div className={styles.portfolio_image}>
                <img
                    src={imageSrc}
                    style={{
                        width: imageWidth,
                        height: imageHeight
                    }}
                    alt='Porfolio Item'
                />
                <span className={styles.portfolio_description}>{description}</span>

            </div>
            <div>
                <div className={styles.portfolio_input_dots}>
                    <span className={styles.portfolio_title} >{title}</span>
                    {!socialStatus.length ? (
                        <label>...</label>
                    ) : (
                        <div className={styles.socials}>
                            {socialStatus?.map(({icon,src}) => (
                                <a key={src} href={src} target='_blank' rel='noreferrer' className={styles.social}>
                                    <i className={icon} />
                                </a>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PortfolioItem