import styles from './button.module.scss';
import cn from 'classnames'

const Button = ({
                    disabled,
                    type,
                    text,
                    onClick,
                    onSubmit,
                    isDark,
                    isReadMore,
                    isSocial,
                }) => {
    const isPrimaryButton = !isSocial && !isReadMore
    return (
        <button
            type={type}
            disabled={disabled}
            onClick={onClick}
            onSubmit={onSubmit}
            className={cn({
                [styles.button_primary]: isPrimaryButton,
                [styles.button_dark]: isPrimaryButton && isDark,
                [styles.read_more]: isReadMore,
            })}
        >
            {text}
        </button>
    )
}

export default Button