import { Link } from "react-router-dom";
import styles from './linkButton.module.scss';
import cn from 'classnames'

const LinkButton = ({
                    to,
                    isDark,
                    children
                }) => {
    return (
        <Link
            to={to}
            className={`${styles.link_primary} ${cn({
                [styles.link_dark]: isDark
            })}`}
        >
            
                {children}
        </Link>
    )
}

export default LinkButton