import BlackImage from '../assets/black.png';
import WhiteImage from '../assets/white.png'

const PolicyImages = () => (
    <div className="privacy--image-container">
        <a href={BlackImage} download="ODD brand logo in black" >
            <img src={BlackImage} alt="Brand Identity in black" />
        </a>
        <a href={WhiteImage} download="ODD brand logo in white" >
            <img src={WhiteImage} alt="Brand Identity in white" />
        </a>
    </div>
);


export default PolicyImages